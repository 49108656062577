<template>
  <div>
    <ProfileReadMyStory
      :stories="user.faq || []"
      @update="onUpdate"
      @dirty="hasUserChanges = true" />
    <StepperButtons
      :isSaving="isSaving"
      @continue="clickContinue"
      @skip="$emit('complete')" />
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import { updateUser } from "@/services/api.service.js";
  const api = { updateUser };
  import StepperButtons from "@/components/common/stepper/StepperButtons.vue";
  import ProfileReadMyStory from "@/components/ProfileReadMyStory.vue";

  export default {
    name: "StepReadMyStory",
    components: {
      StepperButtons,
      ProfileReadMyStory,
    },

    data() {
      return {
        stories: [],
        hasUserChanges: false,
        isSaving: false,
      };
    },

    computed: {
      ...mapState(["user"]),
    },

    watch: {
      "user.faq": {
        immediate: true,
        handler(val) {
          this.stories = val || [];
        },
      },
    },

    methods: {
      onUpdate(stories) {
        this.stories = stories;
      },

      async clickContinue() {
        const user = { ...this.user, faq: this.stories };

        try {
          if (this.hasUserChanges) {
            this.isSaving = true;
            await api.updateUser(user);
            this.$store.dispatch("setUser", user);
          }
          this.$emit("complete");
        } catch (error) {
          this.$toast.error(this.$lang.editProfile_Error);
        } finally {
          this.isSaving = false;
        }
      },
    },
  };
</script>
